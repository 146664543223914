import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Footer from '../component/footer/Footer';
import HeaderGravity from '../component/header/HeaderGravity';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="About Gravity Media" />
        {/* End Pagehelmet  */}

        <HeaderGravity />

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h1 className="title theme-gradient">ABOUT GRAVITY MEDIA</h1>
                  <p>How our strategic video content can help you to increase leads &amp; sales.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Welcome to Gravity Media -- where your vision meets our creativity! For over 14 years, we've
                            been focused on creating engaging content that boosts brands and helps businesses to boose
                            lead gen and increase sales. We assist medium sized businesses and influencers to generate
                            more interest online in their products and services.
                          </p>
                          <p>
                            Our aim is to provide strategic insight on how to improve your marketing with video, and
                            implement that plan in order to make any website, landing page, or advertisement more
                            powerful. With video, the reach exponentially grows, along with the results of your
                            campaign.
                          </p>
                          <h4 className="title">Why Choose Gravity Media?</h4>
                          <ul className="liststyle">
                            <li>We are attentive listeners</li>
                            <li>We give attention to details and are experienced</li>
                            <li>We are easy to work with</li>
                            <li>
                              We guarantee an increase in leads from our services or else we work with you until you get
                              it, for FREE
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Your success is our success. At Gravity Media, we're dedicated to understanding your
                            business objectives and delivering results that not only meet but exceed your expectations.
                            With our strategic video content, we aim to help you boost those leads, boost sales and
                            create a stronger, lasting impact in your market.
                          </p>
                          <p>
                            Wether you are building an e-course with video content, or creating landing or squeeze pages
                            that need to convert, we can help you isolate what message needs to be communicated in your
                            video so you can maximize the return on your investment.
                          </p>
                          <h4 className="title">Learn about our products</h4>
                          <ul className="liststyle">
                            <li>
                              Tell the story of your business with a{' '}
                              <Link to="/professional-video-services#brand-videos">brand video</Link>
                            </li>
                            <li>
                              Wow potential customers with captivating{' '}
                              <Link to="/professional-video-services#testimonials">testimonials</Link>
                            </li>
                            <li>
                              Generate{' '}
                              <Link to="/professional-video-services#commercials">PPC ads with video content</Link> that
                              convert
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images" />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button className="video-popup" onClick={this.openModal}>
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
