import React, { Component } from 'react';
import axios from 'axios';

class ContactTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: '',
      rnCompany: '',
      rnPhone: '',
      rnEmail: '',
      rnMessage: '',
      disabled: false,
      sending: false,
      error: '',
      success: '',
      messageCount: 0,
    };
  }

  clearSuccess = () => {
    setTimeout(() => {
      this.setState({ success: '' });
    }, 5000);
  };

  submitForm = async (e) => {
    e.preventDefault();
    this.setState({ sending: true, disabled: true, error: '', success: '' });
    const data = {
      name: this.state.rnName,
      company: this.state.rnCompany,
      phone: this.state.rnPhone,
      email: this.state.rnEmail,
      message: this.state.rnMessage,
      action: 'message',
    };
    try {
      const result = await axios.post('/message/', data);
      if (result.data.status === 'success') {
        this.setState((state) => {
          return {
            rnName: '',
            rnCompany: '',
            rnPhone: '',
            rnEmail: '',
            rnMessage: '',
            success: result.data.message,
            messageCount: state.messageCount + 1,
          };
        });
        this.clearSuccess();
      } else {
        this.setState({ error: 'There was an error processing your message. Please try again.' });
      }
    } catch (err) {
      this.setState({ error: 'Your message could not be sent. Please try again.' });
    } finally {
      this.setState({ sending: false, disabled: false });
    }
  };

  render() {
    const disabled = this.state.disabled || this.state.messageCount > 3;

    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Contact Form</h2>
                <p className="description mb-0">
                  Let us know about your project, what your budget is and any timeline you may have for completion.
                  We'll be glad to provide a quote!
                </p>
              </div>
              <div className="form-wrapper">
                <form action="" method="post" onSubmit={this.submitForm}>
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      required
                      value={this.state.rnName}
                      disabled={disabled}
                      onChange={(e) => {
                        this.setState({ rnName: e.target.value });
                      }}
                      placeholder="Your Name *"
                    />
                  </label>

                  <label htmlFor="item02a">
                    <input
                      type="text"
                      name="company"
                      id="item02a"
                      disabled={disabled}
                      value={this.state.rnCompany}
                      onChange={(e) => {
                        this.setState({ rnCompany: e.target.value });
                      }}
                      placeholder="Your Company"
                    />
                  </label>

                  <label htmlFor="item02b">
                    <input
                      type="text"
                      name="phone"
                      id="item02b"
                      disabled={disabled}
                      value={this.state.rnPhone}
                      onChange={(e) => {
                        this.setState({ rnPhone: e.target.value });
                      }}
                      placeholder="Your Phone"
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="email"
                      name="email"
                      id="item02"
                      required
                      disabled={disabled}
                      value={this.state.rnEmail}
                      onChange={(e) => {
                        this.setState({ rnEmail: e.target.value });
                      }}
                      placeholder="Your Email *"
                    />
                  </label>

                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      disabled={disabled}
                      name="message"
                      required
                      value={this.state.rnMessage}
                      onChange={(e) => {
                        this.setState({ rnMessage: e.target.value });
                      }}
                      placeholder="Your Message *"
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    disabled={disabled}
                    id="mc-embedded-subscribe"
                  >
                    {this.state.sending ? (
                      <span>
                        <i className="fa fa-spinner fa-spin" /> Sending...
                      </span>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </form>
                {this.state.error ? <p className="alert alert-danger mt-2">{this.state.error}</p> : null}
                {this.state.success ? <p className="alert alert-success mt-2">{this.state.success}</p> : null}
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img className="w-100" src="/assets/images/gravity-media/about-img.jpg" alt="About Images" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
