import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Footer from '../component/footer/FooterTwo';
import { Helmet } from 'react-helmet';
import HeaderGravity from '../component/header/HeaderGravity';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class Error404 extends Component {
  render() {
    return (
      <>
        <Helmet pageTitle="Page Not Found" description="" meta="" />
        <HeaderGravity />
        {/* Start Page Error  */}
        <div className="error-page-inner bg_color--4">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h1 className="title theme-gradient">404!</h1>
                  <h3 className="sub-title">Page not found</h3>
                  <span>The page you were looking for could not be found.</span>
                  <div className="error-button">
                    <Link className="rn-button-style--2 btn-solid" to="/">
                      Back To Homepage
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Error  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </>
    );
  }
}
export default Error404;
