import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import VideoButton from '../../../elements/Video';

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src="/assets/images/gravity-media/about-img.jpg" alt="About Images" />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title pt-5">
                    <h2 className="title">How We Can Help You</h2>
                    <p className="description">
                      Gravity Media is a video production company located in Clearwater, FL, servicing the entire Tampa
                      Bay Area including St. Pete, Dunedin and Palm Harbor. We film high-quality productions for
                      businesses in many industries, social media influencers, and artists that take their marketing to
                      a greater heights.
                    </p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Get to know us</h3>
                        <p>
                          Find out more about our work and check out our <VideoButton>online portfolio</VideoButton>. To
                          receive a quote, <Link to="/contact">contact us</Link> today.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Work with us</h3>
                        <p>
                          If you are looking to work in the competitive, fun and fast-paced field of digital video
                          production, <Link to="/contact">contact us</Link>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
