import React from 'react';
import ThemeStore from '../../../stores/ThemeStore';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PortfolioList = [
  {
    images: 'que-rico-keto.png',
    category: 'Social Media',
    title: '¡Qué Rico! Keto E-book',
    videoId: 'wCpI3xOL6Gc',
    url: '/portfolio/que-rico-keto',
  },
  {
    images: 'agent-link.png',
    category: 'Corporate',
    title: 'Agent Link Marketing',
    videoId: 'vZKo5hcuuGU',
    url: '/portfolio/agent-link-marketing',
  },
  {
    images: 'nedc1.png',
    category: 'Promotion',
    title: 'New England Design & Construction',
    videoId: '7M7KZLyNjOU',
    url: '/portfolio/nedc',
  },
  {
    images: 'nedc2.png',
    category: 'Promotion',
    title: 'NEDC Client Introduction',
    videoId: '6MHIzzLUnxY',
    url: '/portfolio/nedc-2',
  },
  {
    images: 'tpq.png',
    category: 'Product',
    title: 'The Pasta Queen Collection',
    videoId: 'qO83cWqxRCA',
    url: '/portfolio/tpq-collection',
  },
  {
    images: 'tpq2.png',
    category: 'Social Media',
    title: 'The Pasta Queen',
    videoId: 'xsBXqD2PqZI',
    url: '/portfolio/tpq-carbonara',
  },
  {
    images: 'visavis.png',
    category: 'Documentary',
    title: 'Vis a Vis Jewelry',
    videoId: 'NuEU_oUyDNM',
    url: '/portfolio/vis-a-vis',
  },
  {
    images: 'onetoone.png',
    category: 'Promotional',
    title: 'One to One Physical Therapy',
    videoId: 'Gi483YowEo0',
    url: '/portfolio/one-to-one-pt',
  },
];

const CreativePortfolio = () => {
  React.useEffect(() => {
    window.addEventListener('scroll', scrollStart);

    return () => {
      window.removeEventListener('scroll', scrollStart);
    };
  }, []);

  const scrollStart = () => {
    if (ThemeStore.isVideoModalOpen) {
      ThemeStore.setVideoModalState(false);
    }
  };

  return (
    <div>
      {/* Start Portfolio Area  */}
      <div className="creative-portfolio-wrapper bg_color--1">
        <div className="creative-portfolio-wrapper plr--10">
          <div className="row row--5">
            {PortfolioList.map((value, i) => (
              <div className="col-lg-3 col-md-6 col-12" key={i}>
                <div className="portfolio-style--3">
                  <div className="thumbnail">
                    <Link to={value.url} title={`Portfolio Project: ` + value.title}>
                      <img
                        className="w-100"
                        src={`/assets/images/gm-portfolio/portfolio-${value.images}`}
                        alt="Portfolio Images"
                      />
                    </Link>
                  </div>
                  <div className="content">
                    <p className="portfoliotype">{value.category}</p>
                    <h4 className="title">
                      <Link to={value.url}>{value.title}</Link>
                    </h4>
                    <div className="portfolio-btn">
                      <Link to={value.url} className="rn-btn text-white" title={`Portfolio Project: ` + value.title}>
                        Watch
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Portfolio Area  */}
    </div>
  );
};

export default CreativePortfolio;
