import { makeAutoObservable } from 'mobx';

class ThemeStore {
  isVideoModalOpen = false;
  lastScrollPos = 0;

  constructor() {
    makeAutoObservable(this);
  }

  toggleVideoModal = () => {
    this.isVideoModalOpen = !this.isVideoModalOpen;
  };

  setVideoModalState = (val) => {
    this.isVideoModalOpen = val;
  };

  setScrollPos = (val) => {
    this.lastScrollPos = val;
  };
}

export default new ThemeStore();
